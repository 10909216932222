import { Store, select } from '@ngrx/store'
import { Injectable, OnDestroy } from '@angular/core'
import { AppState } from '@boa/app/app.store'
import { FeatureFlag } from '@boa/booster-types'

@Injectable()
export class FeatureFlagService implements OnDestroy {
  private featureFlags$ = this.store.pipe(select((s) => s.user.featureFlags))
  private featureFlags: FeatureFlag[] = []
  private featureFlagsSubscription = this.featureFlags$.subscribe(
    (featureFlags) => {
      if (featureFlags) {
        this.featureFlags = featureFlags
      }
    }
  )

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    if (this.featureFlagsSubscription) {
      this.featureFlagsSubscription.unsubscribe()
    }
  }

  isFeatureFlagEnabled(featureFlag: string): boolean {
    return this.featureFlags?.find((ff) => ff._id === featureFlag)?.enabled
  }
}
